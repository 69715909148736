<template>
	<div class="prebuild">
		<div class="btn-group modifier">
			<button
				class="btn small dull with-icon vip"
				aria-live="assertive"
				@click="toggleVip"
			>
				<svg width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-if="vip" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:aria-hidden="'true'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.245 5.997l6.92 8.553L4.42 5.997zM5.47 5.995l5.062-.005L8 14.507 5.47 5.995M2.94 1.412L.251 4.996h3.847c-.78-2.422-.633-1.954-1.16-3.584zM13.064 1.404l2.688 3.584h-3.848c.78-2.422.633-1.954 1.16-3.584zM3.865 1l1.283 3.994.002-.006h5.703L12.135 1h-8.27zM15.756 5.989l-6.92 8.553 2.746-8.553z"/></svg>
				<svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:aria-hidden="'true'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" d="M3.1.7a.5.5 0 01.4-.2h9a.5.5 0 01.4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 01-.8 0L.1 5.3a.5.5 0 010-.6l3-4zm11.386 3.785l-1.806-2.41-.776 2.413 2.582-.003zm-3.633.004l.961-2.989H4.186l.963 2.995 5.704-.006zM5.47 5.495l5.062-.005L8 13.366 5.47 5.495zm-1.371-.999l-.78-2.422-1.818 2.425 2.598-.003zM1.499 5.5l2.92-.003 2.193 6.82L1.5 5.5zm7.889 6.817l2.194-6.828 2.929-.003-5.123 6.831z"/></svg>
				{{ vipToggleTitle }}
			</button>
			<button
				class="btn small dull with-icon edit"
				aria-live="assertive"
				@click="toggleEdit"
			>
				<svg width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-if="edit" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:aria-hidden="'true'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.537 1a1.53 1.53 0 00-1.533 1.533v10.934c0 .85.684 1.533 1.533 1.533h10.934a1.53 1.53 0 001.533-1.533V4.84c-2.35 2.36-4.59 4.63-7.214 7.217l-.008.008c-.188.184-.42.324-.67.404l-2.4.803-.01.004a1.48 1.48 0 01-1.473-.358 1.479 1.479 0 01-.346-1.474v-.002l.805-2.414c.083-.248.225-.478.41-.662L11.461 1z"/><path d="M15.506 1.94a.5.5 0 010 .706L14.463 3.69l-2-2L13.506.646a.5.5 0 01.707 0l1.293 1.293zm-1.75 2.456l-2-2L4.943 9.21a.5.5 0 00-.121.196l-.805 2.414a.25.25 0 00.316.316l2.414-.805a.5.5 0 00.196-.12l6.813-6.814z"/></svg>
				<svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:aria-hidden="'true'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.502 1.94a.5.5 0 010 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 01.707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 00-.121.196l-.805 2.414a.25.25 0 00.316.316l2.414-.805a.5.5 0 00.196-.12l6.813-6.814z"/><path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 002.5 15h11a1.5 1.5 0 001.5-1.5v-6a.5.5 0 00-1 0v6a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5H9a.5.5 0 000-1H2.5A1.5 1.5 0 001 2.5v11z"/></svg>
				{{ editToggleTitle }}
			</button>
		</div>
		<label
			:for="song.id + 'output'"
			class="sr-only"
		>{{ $t('song.prebuild.output') }}</label>
		<input
			:id="song.id + 'output'"
			ref="output"
			type="text"
			readonly="readonly"
			class="output"
			:value="command"
		>
		<div class="btn-group copy">
			<button
				ref="copyButton"
				class="btn small dull with-icon"
				@click="copy"
			>
				<svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:aria-hidden="'true'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" d="M4 1.5H3a2 2 0 00-2 2V14a2 2 0 002 2h10a2 2 0 002-2V3.5a2 2 0 00-2-2h-1v1h1a1 1 0 011 1V14a1 1 0 01-1 1H3a1 1 0 01-1-1V3.5a1 1 0 011-1h1v-1z"/><path fill-rule="evenodd" d="M9.5 1h-3a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm-3-1A1.5 1.5 0 005 1.5v1A1.5 1.5 0 006.5 4h3A1.5 1.5 0 0011 2.5v-1A1.5 1.5 0 009.5 0h-3zM8 7a.5.5 0 01.5.5V9H10a.5.5 0 010 1H8.5v1.5a.5.5 0 01-1 0V10H6a.5.5 0 010-1h1.5V7.5A.5.5 0 018 7z"/></svg>
				{{ $t('song.prebuild.copy.label') }}
			</button>
		</div>
	</div>
</template>

<script lang="js">

export default {
	name: 'Prebuild',
	props: {
		selectedArrangement: {
			type: Object,
			required: true
		},
		song: {
			type: Object,
			required: true
		}
	},
	data () {
		return {
			vip: false,
			edit: false
		};
	},
	computed: {
		command() {
			let command;

			if (this.vip) {
				if (this.edit) {
					command = 'vipedit';
				}
				else {
					command = 'vip';
				}
			}
			else {
				if (this.edit) {
					command = 'edit';
				}
				else {
					command = 'sr';
				}
			}

			return `!${ command } ${ this.song.name } - ${ this.song.artist } ${ this.selectedArrangement.type ? `*${ this.selectedArrangement.type }` : '' }`;
		},
		vipToggleTitle() {
			return this.vip ? this.$t('song.prebuild.vip.label') : this.$t('song.prebuild.regular.label');
		},
		editToggleTitle() {
			return this.edit ? this.$t('song.prebuild.edit.label') : this.$t('song.prebuild.request.label');
		}
	},
	methods: {
		toggleVip() {
			this.vip = !this.vip;
		},
		toggleEdit() {
			this.edit = !this.edit;
		},
		/**
		 * copy the command to the user's clipboard
		 */
		copy() {
			this.$refs.output.select();
			document.execCommand('copy');

			this.$nextTick().then(() => {
				this.$refs.copyButton.focus();
			});
		}
	}
};


</script>

<style lang="scss">
  .prebuild {
		display: grid;
		grid-template-areas: 'modifier output copy';
		grid-template-columns: auto 1fr auto;
		padding: var(--margin);
		border-top: 1px solid var(--filler-2);
		gap: var(--margin);

		@media screen and (max-width: 32rem) {
			grid-template-areas: 'output output output' 'modifier . copy';
			grid-template-columns: auto 1fr auto;
		}

		.button-group {
			display: flex;

			& > button:first-of-type{
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
			}
			& > button:last-of-type{
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
			}
		}

		.output {
			border-radius: 5px;
			background-color: var(--root-bg);
			border: 3px solid #0000;
			cursor: default;
			color: var(--text);
			padding: calc(var(--margin-thin) - 3px) calc(var(--margin) - 3px);
			grid-area: output;
			min-width: 1em;
			transition: border-color ease-in-out 300ms;

			&:focus{
				outline: none;
				border-color: var(--filler-3);
			}
		}
		.modifier {
			grid-area: modifier;
		}
		.copy {
			grid-area: copy;
		}
  }
</style>
