<template lang="html">
	<div
		v-if="hasTags || hasShowlights || hasVocals"
		class="wrapper"
	>
		<ul
			v-if="hasTags"
			class="tag-list"
		>
			<li
				v-for="t in tags"
				:key="t.id"
				class="tag"
				:style="{'background-color': '#'+t.color, 'color': '#'+calcColor(t.color)}"
			>
				{{ i18nFallback('song.tags', t.name) }}
			</li>
		</ul>
		<svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" v-if="hasShowlights" v-tippy="{placement: 'bottom'}" v-bind:svg-inline="''" v-bind:class="'icon'" :data-tippy-content="$t('song.showlight')" v-bind:tabindex="'0'" v-bind:role="'presentation'" v-bind:focusable="'false'"><path d="M12 8a4 4 0 11-8 0 4 4 0 018 0z"/><path fill-rule="evenodd" d="M8 0a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2A.5.5 0 018 0zm0 13a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2A.5.5 0 018 13zm8-5a.5.5 0 01-.5.5h-2a.5.5 0 010-1h2a.5.5 0 01.5.5zM3 8a.5.5 0 01-.5.5h-2a.5.5 0 010-1h2A.5.5 0 013 8zm10.657-5.657a.5.5 0 010 .707l-1.414 1.415a.5.5 0 11-.707-.708l1.414-1.414a.5.5 0 01.707 0zm-9.193 9.193a.5.5 0 010 .707L3.05 13.657a.5.5 0 01-.707-.707l1.414-1.414a.5.5 0 01.707 0zm9.193 2.121a.5.5 0 01-.707 0l-1.414-1.414a.5.5 0 01.707-.707l1.414 1.414a.5.5 0 010 .707zM4.464 4.465a.5.5 0 01-.707 0L2.343 3.05a.5.5 0 11.707-.707l1.414 1.414a.5.5 0 010 .708z"/></svg>
		<svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" v-if="hasVocals" v-tippy="{placement: 'bottom'}" v-bind:svg-inline="''" v-bind:class="'icon'" :data-tippy-content="$t('song.lyrics')" v-bind:tabindex="'0'" v-bind:role="'presentation'" v-bind:focusable="'false'"><path d="M5 3a3 3 0 016 0v5a3 3 0 01-6 0V3z"/><path fill-rule="evenodd" d="M3.5 6.5A.5.5 0 014 7v1a4 4 0 008 0V7a.5.5 0 011 0v1a5 5 0 01-4.5 4.975V15h3a.5.5 0 010 1h-7a.5.5 0 010-1h3v-2.025A5 5 0 013 8V7a.5.5 0 01.5-.5z"/></svg>
	</div>
</template>

<script lang="js">
import mixins from '@/mixins.js';

export default {
	name: 'TagList',
	mixins:[mixins],
	props: {
		tags:{
			type: Array,
			required: false,
			default: () => []
		},
		hasShowlights: {
			type: Boolean,
			required: false,
			default: false
		},
		hasVocals: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		hasTags() {
			return this.tags.length > 0;
		}
	}
};


</script>

<style scoped lang="scss">
  .wrapper {
		display: flex;
		flex-direction: row;
		padding: calc(var(--margin) * 2) var(--margin) 0 calc(var(--margin) * 2);
		align-items: center;
	}
	.tag-list {
		display: flex;
		flex-direction: row;
		margin-right: auto;

		.tag {
			padding: var(--margin);
			border-radius: 5px;

			& + .tag {
				margin-left: var(--margin);
			}
		}
	}
	.icon {
		margin-right: var(--margin);
		&:first-of-type {
			margin-left: auto;
		}
	}
</style>
